import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Section from '../layout/Section';
import heroBg from '../../images/home/hero-bg.jpg';
import { colors } from '../../includes/style';
import Container from '../layout/Container';
import Button from '../utils/Button';
import { Link } from 'gatsby';

const StyledSection = styled(Section)`
  background: ${colors.blue} url(${heroBg});
  background-repeat: no-repeat;
  background-position: center bottom;
  /* background-attachment: scroll; */
  background-size: cover;
  color: #fff;

  ${Container}{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 500px;
    font-family: "Lato", Helvetica, Arial, sans-serif;
  }

  h1{
    font-weight: bold;
  }

  p {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: ;
  }

  button {
    margin-top: 50px;
    font-size: 13px;
    text-transform: uppercase;
  }
`;

type HeroProps = {
  headline?: ReactNode,
  subheadline?: ReactNode,
};
// Regarding H1 tags and SEO https://moz.com/blog/h1-seo-experiment
export default function Hero({
  headline = <>Building the Future</>,
  subheadline = <>One test at a time</>,
}: HeroProps) {
  return <StyledSection>
    <Container>
      <h1 className="hero-headline">{headline}</h1>
      <p className="hero-subheadline">{subheadline}</p>
      <Link to="/contact"><Button type="button">Contact Us</Button></Link>
    </Container>
  </StyledSection>;
}
